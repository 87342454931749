import axios from 'axios';
import { i18n } from 'next-i18next';
import Router from 'next/router';
import { setAxiosError } from '../reduxToolkit/slices/commonSlice';
import store from '../reduxToolkit/store';
import { removeToken } from './auth';
import * as Sentry from '@sentry/nextjs';
const protectedRoutes = ['/exhibitor', '/admin', '/account'];

const initiateAxios = () => {
  const doesRouteStartsWith = (path: string) => !!Router.route.match(new RegExp(`(?=${path})`));

  const isProtected = protectedRoutes.some((route) => doesRouteStartsWith(route));

  axios.interceptors.response.use(
    function (response) {
      store.getState().common.axiosError && store.dispatch(setAxiosError(null));
      return response;
    },
    function (error) {
      console.error(error);
      Sentry.captureException(new Error(error), {
        extra: {
          'AXIOS INTERCEPTOR - ERROR': error,
        },
      });
      if (!i18n) return Promise.reject(error);
      const errorMsg =
        (i18n.language === 'en' && error?.response?.data?.error) || error?.response?.data?.translation?.[`${i18n.language}-${i18n.language?.toUpperCase()}`];
      // Forbidden
      if ((error.response?.status === 403 || error.response?.status === 401) && Router.route.match(new RegExp(`^/login`)) === null) {
        if (!Router.route.includes('account') && Router.route.includes('event')) {
          removeToken();
          return Router.push(Router.asPath);
        }

        removeToken();

        Router.push({
          pathname: isProtected ? '/login' : '/event',
          query: { redirect: Router.asPath },
        });
      }

      Sentry.captureException(new Error(error), {
        extra: {
          'AXIOS INTERCEPTOR - ERROR': error,
        },
      });

      if (errorMsg && error.response?.status !== 405) store.dispatch(setAxiosError(errorMsg));
      return Promise.reject(error);
    }
  );
};

export default initiateAxios;
